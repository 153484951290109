import styled from 'styled-components';
import { breakpoint } from 'styles';

import Typography from 'components/atoms/Typography';

export const StyledDragAndDropWrapper = styled.div`
  position: relative;
  margin-bottom: var(--gap-lg);
`;

export const StyledDragAndDrop = styled.div`
  padding: var(--gap-xxs) var(--gap-xxxxxs);
  color: var(--c-gray-800);
  text-align: center;
  cursor: pointer;
  background: var(--c-white);
  border: 1px dashed var(--c-gray-300);
  border-radius: var(--border-radius);

  &:focus {
    outline: 2px solid var(--focus-color);
    outline-offset: 2px;
  }

  ${breakpoint.md} {
    padding: var(--gap-xxs) var(--gap-xxxxxl);
  }
`;

export const StyledUploadText = styled(Typography)`
  pointer-events: none;

  b {
    font-weight: 500;
  }

  p:nth-child(2) {
    padding-top: var(--gap-xxxs);
  }

  i {
    font-style: normal;
    font-size: 12px;
  }
`;
