import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Icon from 'components/atoms/Icon';
import { StyledError } from 'components/atoms/Input/Input.styles';
import Typography from 'components/atoms/Typography';

import {
  StyledDragAndDrop,
  StyledDragAndDropWrapper,
  StyledUploadText,
} from './DragAndDrop.styles';

import { DragAndDropProps, MailFileType } from './models.d';

const DragAndDrop: FC<DragAndDropProps> = ({
  register,
  required,
  setValue,
  error,
  uploadText,
  isClicked,
}) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (files) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const file: MailFileType = {
          content: reader.result,
          filename: files[0].name,
          type: files[0].type,
          disposition: 'attachment',
        };
        if (isClicked) {
          setValue('file', file, { shouldValidate: true });
        }

        setValue('file', file, { shouldValidate: false });
      };
    },
    onFileDialogCancel: () => {
      if (isClicked) {
        setValue('file', undefined, { shouldValidate: true });
      }
    },
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <StyledDragAndDropWrapper>
      <StyledDragAndDrop {...getRootProps({ className: 'dropzone' })} data-target="hover">
        <input {...register('file', { required })} {...getInputProps()} />
        <Icon name="common-upload" data-target="hover" width="50" height="50" />
        {uploadText ? (
          <StyledUploadText size={14}>{renderRichText(uploadText)}</StyledUploadText>
        ) : null}
        {acceptedFiles[0]?.name ? (
          <Typography data-target="hover">{acceptedFiles[0].name}</Typography>
        ) : null}
      </StyledDragAndDrop>
      {error ? (
        <StyledError
          padding={{ top: 6 }}
          size={14}
          lineHeight={1.4}
          fontWeight={300}
          color="accent-red-300"
        >
          {error}
        </StyledError>
      ) : null}
    </StyledDragAndDropWrapper>
  );
};
export default DragAndDrop;
